<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editvancode") }} | {{ form.van_code }} {{ form.van_name }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseName"
            class="required form-label col-sm-10"
            >{{ $t("vancode") }}</label
          >
          <div class="col-sm-10" style="position: relative">
            <input
              v-if="companyId == 1"
              v-model="form.van_code"
              type="text"
              maxlength="3"
              class="form-control"
              id="mainWarehouseName"
              v-mask-number
              required
            />
            <input
              v-else
              v-model="form.van_code"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
          <div class="col-8 invalid-feedback text-end pe-8">
            <span>กรุณากรอก</span>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="mainWarehouseCode"
            class="required form-label col-sm-10"
            >{{ $t("vanname") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.van_name"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>

        <!-- <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="required form-label col-sm-10"
            >ประเภทแวน</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.van_type"
              required
            >
              <option value="คลังหลัก">คลังหลัก</option>
              <option value="คลังรอง">คลังรอง</option>
              <option value="คลังอื่นๆ">คลังอื่นๆ</option>
            </select>
          </div>
        </div> -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="validationTooltip01"
            class="form-label col-sm-10 required"
            >{{ $t("selectbranch") }}</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.branchId"
            >
              <option v-for="item in branches" :key="item" :value="item.id">
                {{ item.code }} |
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="validationTooltip01"
            class="form-label col-sm-10 required"
            >{{ $t("business_type") }}</label
          >
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.business_type"
            >
              <option value="Pre order">Pre order</option>
              <option value="Cash Van">Cash Van</option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10">{{
            $t("route")
          }}</label>
          <div class="col-sm-10">
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.routeId"
            >
              <option v-for="item in routes" :key="item" :value="item.id">
                {{ item.code }} |
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >เป้ายอดขาย</label
          >
          <div class="col-sm-10">
            <div class="row justify-content-center mb-6">
              <div class="col-sm-4 text-center">
                <label for="">เลือกเดือน</label>
                <a-month-picker
                  :disabled-date="disabledDate"
                  class="form-control ant-date"
                  style="border-radius: 6px"
                  v-model:value="targetMonth"
                  @change="clickMonth(targetMonth)"
                />
              </div>
              <div class="col-sm-6 text-center" style="align-self: center">
                <label for="">ยอดขาย</label>
                <input type="number" class="form-control" v-model="targetAmt" />
              </div>
              <div class="col-sm-2 text-end mt-5" style="align-self: center">
                <button
                  type="button"
                  @click="saveTarget()"
                  class="btn btn-sm btn-light btn-warning ms-3 me-2"
                >
                  {{ $t("save") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="validationTooltip01" class="form-label col-sm-10 mb-4">
            ระบุรูปแบบเลขที่เอกสาร
          </label>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบกำกับ CASHVAN</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_cashvan"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_cashvan_running"
              type="text"
              class="form-control"
              disabled
            />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบกำกับ PRE-ORDER</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transaction_invoice_preorder"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input v-model="form.transaction_invoice_preorder_running" type="text" class="form-control" disabled />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>order number CASHVAN</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_cashvan"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input v-model="form.order_number_cashvan_running" type="text" class="form-control" disabled />
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>order number PRE-ORDER</span>
          </div>
          <!-- ///// -->
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.order_number_preoder"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input v-model="form.order_number_preoder_running" type="text" class="form-control" disabled />
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอโอนสินค้าระหว่างคลังถึงแวน</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_wherehouse_to_van_number"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            <input v-model="form.transfer_wherehouse_to_van_number_running" type="text" class="form-control" disabled />
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบขอโอนสินค้าระหว่างแวน</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.transfer_between_van_number"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            
            <input v-model="form.transfer_between_van_number_running" type="text" class="form-control" disabled />
          </div>

          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>หมายเลขใบลดหนี้</span>
          </div>
          <div class="col-sm-3 mb-2" style="align-self: center">
            <span>รูปแบบ</span>
          </div>
          <div class="col-sm-2 mb-2">
            <input
              v-model="form.due_van_number"
              type="text"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-sm-2 mb-2">
            
            <input v-model="form.due_van_number_running" type="text" class="form-control" disabled />
          </div>

          
        </div>
        <!-- <div class="col d-flex justify-content-center mb-4"></div> -->
        <div class="row justify-content-center mb-6">
          <div class="col-sm-5 text-center">
            <label for="">Select Planning Date</label>
            <a-date-picker
              :disabled-date="disabledDate"
              class="form-control ant-date"
              style="border-radius: 6px"
              v-model:value="planning_date"
              format="DD/MM/YYYY"
              @change="clickDate(planning_date)"
            />
          </div>
          <div class="col-sm-5 text-end mt-4" style="align-self: center">
            <button
              type="button"
              class="btn btn-primary"
              @click="openDialogSearchCustomer()"
            >
              เลือกลูกค้า
            </button>
          </div>
        </div>

        <div class="col d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <div class="tab-content" id="myTabContent">
              <div>
                <!-- <a-table :dataSource="dataItemsShow" :columns="columns">
                  <template #headerCell="{ column }">
                    <template v-if="column.dataIndex === 'no'">
                      {{ $t("no") }}
                    </template>
                    <template v-if="column.dataIndex === 'code'">
                      {{ $t("code") }}
                    </template>
                    <template v-if="column.dataIndex === 'name'">
                      {{ $t("name") }}
                    </template>
                    <template v-if="column.dataIndex === 'date'">
                      {{ $t("date") }}
                    </template>
                    <template v-if="column.dataIndex === 'action'">
                      {{ $t("action") }}
                    </template>
                  </template>
                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'no'">
                      {{ record.no }}
                    </template>
                    <template v-if="column.dataIndex === 'date'">
                      <a-time-picker
                        v-model:value="record.planning_time"
                        format="HH:mm"
                      />
                    </template>
                    <template v-if="column.dataIndex === 'action'">
                      <div class="row">
                        <div class="col-sm-6 d-flex justify-content-md-end">
                          <button
                            type="button"
                            class="btn btn-link btn-color-muted btn-active-color-success p-0"
                            @click="goToEdit(record.id)"
                          >
                            <i class="bi bi-pencil-square"></i>
                          </button>
                        </div>
                        <div class="col-sm-6">
                          <button
                            @mouseenter="hoverBBtn"
                            type="button"
                            class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                            @click="goToDelete(record, index)"
                          >
                            <i class="bi bi-trash3-fill"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table> -->
                <table
                  class="table table-hover table-rounded table-striped border gy-7 gs-7"
                  id="table"
                >
                  <thead>
                    <tr
                      class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
                    ></tr>
                    <tr>
                      <th>{{ $t("no") }}</th>
                      <th>{{ $t("code") }}</th>
                      <th>{{ $t("name") }}</th>
                      <th>{{ $t("date") }}</th>
                      <th>{{ $t("status") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr v-if="dataItemsShow.length < 1">
                      <td class="text-center" colspan="5">ไม่มีข้อมูล</td>
                    </tr> -->
                    <tr v-for="(item, index) in dataItemsShow" :key="index">
                      <td>{{ item.no }}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <!-- <a-time-picker
                          v-model:value="item.planning_time"
                          format="HH:mm"
                        /> -->
                        <input
                          type="text"
                          v-model="item.planning_time"
                          class="form-control"
                          v-mask="'00:00'"
                          placeholder="00:00"
                        />
                      </td>
                      <td>{{ item.checkin_status }}</td>
                      <td>
                        <div class="row">
                          <div class="row">
                            <div class="col-sm-6">
                              <button
                                @mouseenter="hoverBBtn"
                                type="button"
                                class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                                @click="goToDelete(item, index)"
                              >
                                <i class="bi bi-trash3-fill"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right col-sm-11" style="text-align: right">
          <button
            type="button"
            class="btn btn-sm btn-light btn-warning ms-3 me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submitPlanning()"
            :disabled="loadingUpdate"
          >
            <div
              v-if="loadingUpdate"
              class="spinner-grow spinner-grow-sm text-light"
              role="status"
            ></div>
            {{ $t("save") }}
          </button>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit()"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
  <DialogSearchCustomer
    :dialogSearchCustomer="dialogSearchCustomer"
    :vanId="vanId"
    @closeDialogSearchCustomer="closeDialogSearchCustomer"
    @submitSearchCustomer="submitSearchCustomer"
  />
</template>
   <!-- <div
              class="tab-pane fade active"
              id="kt_tab_monday"
              role="tabpanel"
            >
              monday
            </div>
            <div
              class="tab-pane fade active"
              id="kt_tab_tuesday"
              role="tabpanel"
            >
              tuesday
            </div>
            <div
              class="tab-pane fade active"
              id="kt_tab_wednesday"
              role="tabpanel"
            >
              wednesday
            </div>
            <div
              class="tab-pane fade active"
              id="kt_tab_thursday"
              role="tabpanel"
            >
              thursday
            </div>
            <div
              class="tab-pane fade active"
              id="kt_tab_friday"
              role="tabpanel"
            >
              friday
            </div>
            <div
              class="tab-pane fade active"
              id="kt_tab_saturday"
              role="tabpanel"
            >
              saturday
            </div> -->

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";
import customerApi from "@/api/customer/";
import customerAPI from "@/api/customer/";
import DialogSearchCustomer from "./components/dialogSearchCustomer.vue";
import moment from "moment";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import draggable from "vuedraggable";


export default {
  setup() {
    document.title = "CHOMTHANA | แก้ไขกลุ่มสินค้าหลัก";
    const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < dayjs().add(-1, "day");
      // return current && current < dayjs().endOf("day");
    };
    return {
      disabledDate,
      dayjs,
    };
  },

  components: {
    Loader,
    DialogSearchCustomer,
    FullCalendar,
    draggable,
  },
  data: () => ({
    drag: false,
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      // main_product_group_code: "",
      // main_product_group_name: "",
      // transaction_invoice_cashvan:"",
      // transaction_invoice_cashvan_running:"",
    },
    targetMonth: dayjs(new Date()),
    targetAmt: "",
    branches: [],
    routes: [],
    
    companyId: "",
    days: [
      { day: "sunday", value: 0 },
      { day: "monday", value: 1 },
      { day: "tuesday", value: 2 },
      { day: "wednesday", value: 3 },
      { day: "thursday", value: 4 },
      { day: "friday", value: 5 },
      { day: "saturday", value: 6 },
    ],
    dialogSearchCustomer: false,
    dataItemsShow: [],
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      { title: "name", dataIndex: "name" },
      { title: "date", dataIndex: "date" },
      { title: "checkin_status", dataIndex: "checkin_status" },
      { title: "", dataIndex: "action" },
    ],
    today: new Date(),
    tabtoday: 0,
    planning_date: "",
    dataPlanings: [],
    itemData: [],
    vanId: null,
  }),

  async created() {
    this.companyId = localStorage.getItem("companyId");
    this.getAllBranch();
    this.getAllRoute();
    this.getOne();
    this.getPlanning();
    this.clickMonth();
    this.checkDate();
    this.vanId = this.$route.query.id;
    this.checkMenu();
  },
  async mounted() {
    // this.getMainProduct();
    await this.getOne();
    await this.api1();
    await this.api2();
    await this.api3();
    await this.api4();
    await this.api5();
    await this.api6();
    await this.api7();
   
  },
  methods: {
    async api1() {
      let responseData = await whApi.van.getRunning(
        this.form.transaction_invoice_cashvan
      );
      this.form.transaction_invoice_cashvan_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api2() {
      let responseData = await whApi.van.getRunning(
        this.form.transaction_invoice_preorder
      );
      this.form.transaction_invoice_preorder_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api3() {
      let responseData = await whApi.van.getRunning(
        this.form.order_number_cashvan
      );
      this.form.order_number_cashvan_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api4() {
      let responseData = await whApi.van.getRunning(
        this.form.order_number_preoder
      );
      this.form.order_number_preoder_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api5() {
      let responseData = await whApi.van.getRunning(
        this.form.transfer_wherehouse_to_van_number
      );
      this.form.transfer_wherehouse_to_van_number_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api6() {
      let responseData = await whApi.van.getRunning(
        this.form.transfer_between_van_number
      );
      this.form.transfer_between_van_number_running =
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    async api7() {
      let responseData = await whApi.van.getRunning(
        this.form.due_van_number
      );
      this.form.due_van_number_running = 
        responseData.data[0]
        ? "66" + (responseData.data[0].runningNumber + "").padStart(5, "0")
        : "6600000";
    },
    
    async saveTarget() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let body = {
            targetMonth:
              dayjs(this.targetMonth.$d.toLocaleDateString()).format(
                "YYYY-MM"
              ) + "-01",
            targetAmt: this.targetAmt,
            companyId: parseInt(localStorage.getItem("companyId")),
            vanId: this.$route.query.id,
          };
          console.log("body", body);
          this.loadingUpdate = true;
          let updateResponse = [];
          updateResponse = await whApi.van.createTargetAmt(body);
          if (updateResponse.response_status === "SUCCESS") {
            Swal.fire({
              icon: "success",
              title: `แก้ไขสำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.clickDate(this.targetMonth);
            });
            this.loadingUpdate = false;
          } else {
            Swal.fire({
              icon: "error",
              title: `${updateResponse.message}`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadingUpdate = false;
          }
        }
      });
    },
    async clickMonth() {
      let responseTarget = [];
      responseTarget = await whApi.van.getTargetAmt(
        dayjs(this.targetMonth.$d.toLocaleDateString()).format("YYYY-MM"),
        localStorage.getItem("companyId"),
        this.$route.query.id
      );
      // console.log("responseTarget.data", responseTarget.data);
      this.targetAmt = responseTarget.data[0].targetAmt;
    },
    async clickDate(value) {
      console.log("value", value);
      let responseData = [];
      this.dataItemsShow = [];
      try {
        responseData = await whApi.van.getPlanningByDate(
          this.$route.query.id,
          this.targetMonth
          // value.$d.toLocaleDateString()
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // console.log('responseData.data',responseData.data);
        this.itemData = responseData.data[0];
        responseData.data[0].vanPlanningItems.forEach((element, index) => {
          this.dataItemsShow.push({
            no: index + 1,
            customerId: element.customer.id,
            code: element.customer.code,
            name: element.customer.name,
            checkin_status: element.checkin_status,
            // planning_time: moment(element.planning_time, "YYYY-MM-DD HH:mm"),
            planning_time: element.planning_time,
          });
        });
      }
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    checkDate() {
      this.tabtoday = this.today.getDay();
    },

    async getAllRoute(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerApi.route.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.routes = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllBranch(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.van.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
        };
        this.loading = false;
      }
    },
    async getPlanning() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.van.getVanPlanning(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.dataPlanings = getOneResponse.data;
        console.log("getPlanning", getOneResponse.data);
        // this.form = {
        //   ...getOneResponse.data,
        // };
        this.loading = false;
      }
    },
    async submitPlanning() {
      // console.log("dataItemsShow", this.dataItemsShow);
      // console.log("dataPlanings", this.dataPlanings);
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log(this.itemData);
          if (this.itemData) {
            let updateResponse = [];
            let vanPlanningItems = [];
            this.dataItemsShow.forEach((element) => {
              console.log("element", element);
              vanPlanningItems.push({
                customerId: element.id ? element.id : element.customerId,
                // planning_time: element.planning_time
                //   ? element.planning_time
                //   : moment(element.planning_time.$d, "YYYY-MM-DD HH:mm"),
                planning_time: element.planning_time,
                checkin_status: element.checkin_status
                  ? element.checkin_status
                  : "รอดำเนินการ",
              });
            });
            let body = {
              planning_date: this.planning_date.$d.toLocaleDateString(),
              vanPlanningItems: vanPlanningItems,
              // vanPlanningItems: this.dataItemsShow,
              vanId: this.$route.query.id,
              // status: "รอดำเนินการ",
            };
            console.log("body update", body);
            updateResponse = await whApi.van.updatePlanning(
              this.dataPlanings[0].id,
              body
            );
            if (updateResponse.response_status === "SUCCESS") {
              console.log(updateResponse.data);
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            this.loadingUpdate = false;
          } else {
            let createResponse = [];
            let vanPlanningItems = [];
            this.dataItemsShow.forEach((element) => {
              vanPlanningItems.push({
                customerId: element.id,
                // planning_time: moment(
                //   element.planning_time.$d,
                //   "YYYY-MM-DD HH:mm"
                // ),
                planning_time: element.planning_time,
                checkin_status: element.checkin_status
                  ? element.checkin_status
                  : "รอดำเนินการ",
              });
            });
            let body = {
              planning_date: this.planning_date.$d.toLocaleDateString(),
              vanPlanningItems: vanPlanningItems,
              vanId: this.$route.query.id,
              status: "รอดำเนินการ",
            };
            createResponse = await whApi.van.createPlanning(body);
            if (createResponse.response_status === "SUCCESS") {
              console.log(createResponse.data);
              Swal.fire({
                icon: "success",
                title: `เพิ่มข้อมูลสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            this.loadingUpdate = false;
          }
        }
      });
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;

          this.form = {
            ...this.form,
            // status: this.form.status === "2" ? "0" : this.form.status,
            companyId: parseInt(localStorage.getItem("companyId")),
          };

          // console.log("body", body);
          if (this.isValid) {
            this.loadingUpdate = true;
            updateResponse = await whApi.van.update(
              this.$route.query.id,
              this.form
            );
            if (updateResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/warehouse/van",
                  query: {},
                });
              });
              this.loadingUpdate = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${updateResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingUpdate = false;
            }
          }
        }
      });
    },
    checkFormIsEmpty() {
      !this.form.van_name
        ? (this.isValid = false)
        : !this.form.van_code
        ? (this.isValid = false)
        : !this.form.branchId
        ? (this.isValid = false)
        : !this.form.business_type
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/van",
        query: {},
      });
    },
    closeDialogSearchCustomer() {
      this.dialogSearchCustomer = false;
    },
    openDialogSearchCustomer() {
      this.dialogSearchCustomer = true;
    },
    submitSearchCustomer(val) {
      // console.log("val", val);
      // console.log("this.dataItemsShow", this.dataItemsShow);
      let isDupp = this.dataItemsShow.filter(
        (element) => val.id == element.customerId
      );
      if (isDupp.length > 0) {
        Swal.fire({
          icon: "error",
          title: "เพิ่มข้อมูลซ้ำ",
          showConfirmButton: false,
          timer: 1500,
        });
        this.dialogSearchCustomer = false;
      } else {
        val.forEach(element => {
        this.dataItemsShow.push({
          ...element,
          checkin_status: element.checkin_status
            ? element.checkin_status
            : "รอดำเนินการ",
        });  
        });
        // this.dataItemsShow.push({
        //   ...val,
        //   checkin_status: val.checkin_status
        //     ? val.checkin_status
        //     : "รอดำเนินการ",
        // });
        this.dialogSearchCustomer = false;
      }
      // console.log("this.dataItemsShow", this.dataItemsShow);
    },
    formatDate(date, value) {
      if (date) {
        const oldDate = moment(date)
          .subtract(value, "days")
          .format("DD/MM/YYYY");
        // const year = parseInt(oldDate.substring(6, 10)) + 543;
        // const newDate = moment(date).format(`DD/MM/${year}`);
        return oldDate;
      } else {
        return "-";
      }
    },
    goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        html: `ต้องการลบ${item.name} หรือไม่ ?`,
        showCancelButton: true,
        width: 450,
      }).then((result) => {
        if (result.isConfirmed) {
          this.dataItemsShow.splice(index, 1);
        }
      });
    },
  },
};
</script>

